import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import favicon from "../static/favicon.png";

function SEO({ description, lang, meta, title, children, type }) {
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                }
            }
        }
    `)

    const metaDescription = description || site.siteMetadata.description;

    return (
        <Helmet
            htmlAttributes={{
                lang: (typeof window !== 'undefined' && window.localStorage) ? window.localStorage.language : lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `${type || 'website'}`,
                },
                {
                    property: `og:url`,
                    content: `${typeof window !== 'undefined' ? window.location.href : ''}`,
                }
            ].concat(meta)}
        >
            <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
            <meta property="og:title" content={`Gruppo Pam`}/>
            {typeof window !== 'undefined' &&
                <meta property="og:url" content={`${window.location}`}/>
            }
            {children}
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `it`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default SEO
